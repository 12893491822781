import React from "react";
import { useHistory } from "react-router-dom";
import { URL_VIDEODETAIL } from "../../../../../app/src/Components/Routes/Location";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import defaultBanner from "../../../../../app/src/Resources/Images/default_banner.png";

const BannerContentComponent = ({ playlist = [], title }) => {
  const history = useHistory();
  if (!Array.isArray(playlist) || playlist.length === 0) return null;

  const bannerContent = playlist[0];

  const imageSrc =
    bannerContent.background ||
    bannerContent.banner ||
    defaultBanner;

  const handleClick = () => {
    const contentId =
      bannerContent.id || bannerContent.contents?.[0]?.id;

    if (contentId) {
      history.push(`${URL_VIDEODETAIL}/${contentId}`);
    }
  };

  return (
    <div>
      <div
        className={`${MULTITENANT_CONSTANTS[TENANT].classPlaylistHighlightItem} bannerClick`}
        key={(bannerContent.id || bannerContent.contents?.[0]?.id) + "-bannercontent"}
      >
        <div
          className={
            MULTITENANT_CONSTANTS[TENANT].bannerHighlightedHeightClassName +
            " playlistHighlightHover"
          }
          onClick={handleClick}
        >
          <img
            src={imageSrc}
            alt={bannerContent.title || bannerContent.contents?.[0]?.title || title}
            className={MULTITENANT_CONSTANTS[TENANT].classHeight}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerContentComponent;