export const TURN_OFF_LOADING_ACTION = "TURN_OFF_LOADING_ACTION";
export const TURN_ON_LOADING_ACTION = "TURN_ON_LOADING_ACTION";
export const DISPLAY_SESSION_EXPIRED_MODAL = "DISPLAY_SESSION_EXPIRED_MODAL";
export const HIDE_SESSION_EXPIRED_MODAL = "HIDE_SESSION_EXPIRED_MODAL";
export const LOAD_TENANT_STYLES = "LOAD_TENANT_STYLES";
export const STORE_PRODUCT_URL = "STORE_PRODUCT_URL";
export const TOGGLE_PASSWORD_MODE = "TOGGLE_PASSWORD_MODE";
export const DISPLAY_ON_BOARDING = "DISPLAY_ON_BOARDING";

export const SELECT_MENU_SECTION = "SELECT_MENU_SECTION";
export const SELECTED_SECTION = "SELECTED_SECTION";

export const SELECT_MENU_SCREEN_ID = "SELECT_MENU_SCREEN_ID";

export const STORE_HOME_SECTION = "STORE_HOME_SECTION";

export const SECTIONS_MENU = "SECTIONS_MENU";

export const SAVE_USER_DATA = "SAVE_USER_DATA";

export const USER_LOGIN = "USER_LOGIN";

export const SAVE_TENANT_LANGUAGES = "SAVE_TENANT_LANGUAGES";

export const SAVE_TENANT_SHOW_PREVIEWS = "SAVE_TENANT_SHOW_PREVIEWS";

export const SAVE_SEARCH_TEXT = "SAVE_SEARCH_TEXT";

export const SAVE_SPONSORS = "SAVE_SPONSORS";

export const SAVE_INTERFACE_URLS = "SAVE_INTERFACE_URLS";

export const SAVE_LEGAL = "SAVE_LEGAL";

export const SAVE_RULES = "SAVE_RULES";

export const CONTENT_HASH = "CONTENT_HASH";

export const CONTENT_TYPE = "CONTENT_TYPE";

export const USER_URL_AVATAR = "USER_URL_AVATAR";

export const SAVE_LANGUAGE_SELECTED = "SAVE_LANGUAGE_SELECTED";

export const SAVE_PRODUCTS = "SAVE_PRODUCTS";

export const SAVE_TRANSLATIONS = "SAVE_TRANSLATIONS";
