import createStore from "../configureStore";
import rootReducer from "../reducers";
import {
  ANIMATION_GET_GOAL_ACHIEVEMENTS,
  ANIMATION_GET_GOAL_PLAY,
  ANIMATION_GET_GOAL_REWARDS,
  LOCAL_STORAGE_KEY__LOCALE,
  ROUTES_BY_WIDGET_ACTION,
  TYPE_STRING 
} from "./Constants";
import { CONTENT_TYPE_CONTAINER } from "../../../detail/src/Pages/DetailPage/PlaylistConstants";
import defaultBanner from "@app/Resources/Images/default_banner.png";
import { URL_HOME } from "../Components/Routes/Location";
import jwtDecode from "jwt-decode";
import { MULTITENANT_CONSTANTS, TENANT } from "../Resources/Multitenant/tenantConstants";
import Cookies from "js-cookie";
import {getPreview} from "../services/services";
import { isCordovaAvailable } from "./secureStorageUtil";
import i18n from "../i18n";
import {URL_BASE_IMAGE} from "../services/servicesConstants";

export const store = createStore(rootReducer);

export const setAchievementsAndRewardsInLocalStorage = (o) => {
  const achievements = o?.actionResult?.achievements;
  const rewards = o?.actionResult?.rewards;
  if (achievements?.length > 0 || rewards?.length > 0) {
    localStorage.setItem(ANIMATION_GET_GOAL_PLAY, JSON.stringify(1));
    localStorage.setItem(ANIMATION_GET_GOAL_ACHIEVEMENTS, JSON.stringify(!!achievements[0] ? achievements[0] : {}));
    localStorage.setItem(ANIMATION_GET_GOAL_REWARDS, JSON.stringify(!!rewards[0] ? rewards[0] : {}));
  }
};

export const isContentTypeContainer = (typeName) => {
  return typeName === CONTENT_TYPE_CONTAINER;
};

export const actionTypeContainer = (widgetAction) => {
  return !!widgetAction ? ROUTES_BY_WIDGET_ACTION[widgetAction.toUpperCase()] : "/";
};

export const checkMenuActive = (match, location, a, sectionSelected, sections) => {
  if (!sectionSelected) {
    if (location.aboutProps) {
      return location.aboutProps?.dinamic === a?.section?.screenId;
    } else {
      const isFirstHomeSection = sections.indexOf(a) === 0;
      return location.pathname === URL_HOME && !!isFirstHomeSection;
    }
  } else {
    return sectionSelected === a?.section?.iconId;
  }
};

export const handleShowPassword = (numberPassword, showPassword) => {
  let arrayInputs = [];
  switch (numberPassword) {
    case 0:
      arrayInputs = [!showPassword[0], showPassword[1], showPassword[2]];
      break;
    case 1:
      arrayInputs = [showPassword[0], !showPassword[1], showPassword[2]];
      break;
    case 2:
      arrayInputs = [showPassword[0], showPassword[1], !showPassword[2]];
      break;
    default:
      return arrayInputs;
  }
  return arrayInputs;
};

export const renderImage = (content) => {
  return content.banner?.url ? content.banner.url : defaultBanner;
};

export const renderImageBannerData = (contentBannerData) => {
  let urlImage = null;

  let bannerUrl = typeof contentBannerData?.banner === TYPE_STRING
    ? contentBannerData.banner
    : contentBannerData?.banner?.data?.url
    ? `${URL_BASE_IMAGE}${contentBannerData.banner.data.url}`
    : null;

  let backgroundUrl = typeof contentBannerData?.background === TYPE_STRING
    ? contentBannerData.background
    : contentBannerData?.background?.id?.data?.asset_url
    ? `${URL_BASE_IMAGE}${contentBannerData.background.id.data.asset_url}`
    : null;
  if (bannerUrl) {
    urlImage = bannerUrl;
  } else if (backgroundUrl) {
    urlImage = backgroundUrl;
  } else {
    urlImage = defaultBanner;
  }
  return urlImage;
};

export const renderImageUrl = (url) => {
  return `${url}`;
};

export const generateSlug = (text) => {
  const removeAccents = (str) => {
    const accents = [
      /[\300-\306]/g,
      /[\340-\346]/g, // A, a
      /[\310-\313]/g,
      /[\350-\353]/g, // E, e
      /[\314-\317]/g,
      /[\354-\357]/g, // I, i
      /[\322-\330]/g,
      /[\362-\370]/g, // O, o
      /[\331-\334]/g,
      /[\371-\374]/g, // U, u
      /[\321]/g,
      /[\361]/g, // N, n
      /[\307]/g,
      /[\347]/g, // C, c
    ];

    const nonAccents = ["A", "a", "E", "e", "I", "i", "O", "o", "U", "u", "N", "n", "C", "c"];

    for (let i = 0; i < accents.length; i++) {
      str = str.replace(accents[i], nonAccents[i]);
    }

    return str;
  };

  return removeAccents(text)
    .toLowerCase()
    .replace(/[^\w\s-]/g, "") // Remover caracteres especiales
    .replace(/\s+/g, "-") // Reemplazar espacios en blanco por guiones
    .replace(/--+/g, "-") // Reemplazar múltiples guiones por uno solo
    .trim(); // Eliminar espacios en blanco al principio y al final
};

export const decodeToken = (token) => {
  const userData = jwtDecode(token);

  return userData.locale;
};

export const setHtmlLang = (lang) => {
  document.documentElement.lang = lang;
};

export const getTranslatedName = (node) => {
  const translation = node?.translations?.find((t) => t.language === getLang());
  return translation?.name || node?.name; // Si hay traducción, usa esa, sino usa el nombre por defecto
};

export const getTranslatedCategory = (category) => {
  const translation = category?.current?.translations?.find((t) => t.language === getLang());
  return translation?.name || category?.current?.name;
};
export const getTranslatedSubsectionName = (subsection) => {
  const translation = subsection?.translations?.find((t) => t.language === getLang());

  // Si encuentra una traducción, devuelve el nombre traducido, si no, el nombre por defecto
  return translation?.name || subsection?.name;
};
export const getLang = () => {
  if (isCordovaAvailable()) {
    return i18n.language;
  }
  let language = localStorage.getItem(LOCAL_STORAGE_KEY__LOCALE);
  if (!language) {
    language = document.documentElement.lang;
    if (!language) {
      language = MULTITENANT_CONSTANTS[TENANT].language;
    }
  }
  return language;
};

const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Tiempo actual en segundos
    return decodedToken && decodedToken.exp  < currentTime && decodedToken.sub;
  } catch (error) {
    return true;
  }
};

const getTokenFromCookie = () => {
  const validToken = !isTokenExpired(Cookies.get("TIIVII_accessToken"));
  return validToken ? Cookies.get("TIIVII_accessToken") : null;
};

const isTokenValidForClient = (cookieToken, keycloakToken) => {
  if (!cookieToken || !keycloakToken) return false;

  const decodedCookieToken = jwtDecode(cookieToken);
  const decodedKeycloakToken = jwtDecode(keycloakToken);

  return decodedCookieToken.sub === decodedKeycloakToken.sub;
};

export const getToken = (keycloak) => {
  let cookieToken = getTokenFromCookie();
  const keycloakToken = keycloak?.token;
  const isTheSameUser = isTokenValidForClient(cookieToken, keycloakToken);
  if ((!cookieToken && !!keycloakToken) || (!!keycloakToken && !isTheSameUser)) {
      saveTokenToCookie(keycloakToken);
      cookieToken = keycloakToken
    };
  return cookieToken;
}

const saveTokenToCookie = (token) => {
  Cookies.remove('TIIVII_accessToken');
  Cookies.set('TIIVII_accessToken', token, { expires: 60 }); // La cookie expirará en 60 días
};

export const removeTokenFromCookie = () => {
  Cookies.remove('TIIVII_accessToken');
};

export const getSectionName = (section) => {
  let name = section?.translations?.find((t) => t.language === getLang())?.name;
  if (!name) {
    name = section?.name;
  }
  return name;
};

export const getWidgetTitle = (widget) => {
  let title = widget?.translations?.find((t) => t.language === getLang())?.title;
  if (!title) {
    title = widget?.title;
  }
  return title;
};
export const getWidgetDescription = (widget) => {
  let description = widget?.translations?.find((t) => t.language === getLang())?.description;
  if (!description) {
    description = widget?.description;
  }
  return description;
};
export const getWidgetSubtitle = (widget) => {
  let subtitle = widget?.translations?.find((t) => t.language === getLang())?.subtitle;
  if (!subtitle) {
    subtitle = widget?.subtitle;
  }
  return subtitle;
};

export const getContentCodeByLanguage = (content) => {
  let title = content?.translations?.find((t) => t.language === getLang())?.code;
  if (!title) {
    title = content?.code;
  }
  return title;
};

const callbackPreview = (data) => {
  const isMobile = false;
  let urlPreview = "";
  const getUrl = (source) => {
    return source?.webm || source?.mp4 || null;
  };

  if (isMobile) {
    urlPreview = getUrl(data?.mobile) || getUrl(data?.web);
  } else {
    urlPreview = getUrl(data?.web) || getUrl(data?.mobile);
  }
  return urlPreview;
};

export const getPreviewUrl = async (contentId) => {
  try {
    const data = await getPreview(contentId);
    const urlPreview = callbackPreview(data);
    return urlPreview;
  } catch (error) {
    console.error("Error fetching preview URL:", error);
  }
}

export const generateCSSFilter = (targetHex) => {
  const filterMap = {
    "#4F8553": "invert(34%) sepia(15%) saturate(463%) hue-rotate(92deg) brightness(94%) contrast(91%)",
    "#F08080": "invert(61%) sepia(43%) saturate(423%) hue-rotate(334deg) brightness(95%) contrast(90%)",
    "#FEC002": "invert(81%) sepia(84%) saturate(645%) hue-rotate(359deg) brightness(101%) contrast(104%)",
    "#F67A69": "invert(44%) sepia(79%) saturate(393%) hue-rotate(310deg) brightness(101%) contrast(102%)",
  };
  if (filterMap[targetHex]) {
    return filterMap[targetHex];
  } else {
    console.warn(`El color ${targetHex} no tiene un filtro asociado en el mapeo.`);
    return "";
  }
};

export const applySidebarHoverFilter = () => {
  const sidebarHoverColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--sidebarHoverIconColor")
    .trim();

  /*console.log("Sidebar Hover Color obtenido:", sidebarHoverColor);*/

  if (sidebarHoverColor && /^#[0-9A-Fa-f]{6}$/.test(sidebarHoverColor)) {
    const cssFilter = generateCSSFilter(sidebarHoverColor); // Busca el filtro asociado en el mapeo
    if (cssFilter) {
      /*console.log("Generated CSS Filter:", cssFilter); // Verifica el filtro generado*/
      document.documentElement.style.setProperty("--iconHoverFilter", cssFilter); // Aplica el filtro
    } else {
      console.warn(`No se aplicó ningún filtro para el color ${sidebarHoverColor}.`);
    }
  } else {
    console.warn("El color no es válido o está undefined:", sidebarHoverColor);
  }
};

applySidebarHoverFilter();
