import React, { useRef, useState } from "react";
import { APPNAME, FORM_STEPS, KEYCLOAK_CLIENT_ID } from "../../../Utils/Constants";
import { URL_BASE, URL_IDEN } from "../../../services/servicesConstants";
import "./LoginFormBackend.css";
import { useTranslation } from "react-i18next";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../Resources/Multitenant/tenantConstants";
import { initializeKeycloak } from "../../../Utils/keycloak";
import { userLoginActionCreator } from "../../../actions/commonActions";
import { useDispatch } from "react-redux";
import AlertNotification from "../../../Components/GUI/Alert/Alert";
import { isCordovaAvailable, setTokensInSecureStorage } from "../../../Utils/secureStorageUtil";
import logoTiivii from "./../../../../../app/src/Resources/Images/logo_tiivii.svg";
import { useEffect } from "react";

const loginService = (appname, body, callback, errorCallback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
      .then((response) => response.json())
      .then((data) => callback({ data }))
      .catch((err) => errorCallback(err));
};

const LoginFormBackend = ({ tenantLanguages, changeFormStep, handleLogin, handleNavigateAsGuest }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let timeoutId;
  const ERROR_TIME_IN_MILIS = 5000;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  //const [rememberLogin, setRememberLogin] = useState(true);
  const [loginError, setLoginError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const cordovaAvailable = useRef(false);

  useEffect(() => {
    cordovaAvailable.current = isCordovaAvailable();
    /*
    if (isCordovaAvailable() && !isSecureStorageInstantiated()) {
      setRememberLogin(false);
    }
    */
  }, []);

  // Almacenar tokens y nombre de usuario de forma segura en dispositivos móviles
  const setTokensAndUsernameInAndroid = (token, refreshToken, username) => {
    if (cordovaAvailable.current) {
      setTokensInSecureStorage(token, refreshToken, username)
          .then(() => {
            console.log("Tokens almacenados correctamente en SecureStorage");
          })
          .catch((error) => console.error("Error al almacenar tokens:", error));
    } else {
      console.error("Cordova no está disponible, no se puede almacenar información.");
    }
  };

  const languagesName = {
    es: t("lang.es"),
    gl: t("lang.gl"),
    eu: t("lang.eu"),
    en: t("lang.en"),
    ca: t("lang.ca"),
    vc: t("lang.vc"),
    pt: t("lang.pt"),
    fr: t("lang.fr"),
  };

  const setTokensAndUsername = (token, refreshToken, username, remember = true) => {
    if (cordovaAvailable.current) {
      if (!remember) {
        return;
      }
      setTokensAndUsernameInAndroid(token, refreshToken, username);
    } else {
      setTokensAndUsernameInWeb(token, refreshToken, username, remember);
    }
  };

  const setTokensAndUsernameInWeb = (token, refreshToken, username, remember = true) => {
    if (remember) {
      localStorage.setItem("access_token", token);
      localStorage.setItem("refresh_token", refreshToken);
      localStorage.setItem("username", username);
    } else {
      sessionStorage.setItem("access_token", token);
      sessionStorage.setItem("refresh_token", refreshToken);
      sessionStorage.setItem("username", username);
    }
  };

  const loginCallback = (data) => {
    if (!!data?.data?.error_error) {
      loginErrorCallback(data.data);
      return;
    }
    if (data?.data?.access_token) {
      const { access_token, refresh_token } = data.data;
      setTokensAndUsername(access_token, refresh_token, username, true);
      initializeKeycloak(access_token, refresh_token, username);
      dispatch(userLoginActionCreator(username));
      handleLogin();
    } else {
      loginErrorCallback(data);
    }
  };

  const showErrorMessage = (errorText) => {
    clearTimeout(timeoutId);
    setLoginError(errorText);
    timeoutId = setTimeout(() => {
      setLoginError(null);
    }, ERROR_TIME_IN_MILIS);
  }

  const loginErrorCallback = (error) => {
    switch (error?.error_error?.http_status_code) {
      case 400:
        showErrorMessage(t('user.login.error.verification'));
        break;
      default:
      case 401:
        showErrorMessage(t('user.login.error.credentials'));
        break;
    }
  };

  const handleSubmit = (event) => {
    const client_id = KEYCLOAK_CLIENT_ID;
    loginService(APPNAME, { username, password, client_id }, loginCallback, loginErrorCallback);
    event.preventDefault();
  };

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const handleRegister = () => {
    if (!!MULTITENANT_CONSTANTS[TENANT]?.registerUrl) {
      window.location.href = MULTITENANT_CONSTANTS[TENANT].registerUrl;
    } else {
      changeFormStep(FORM_STEPS.REGISTER);
    }
  };

  /*
  const handleChangeRemember = (event) => {
    if (isCordovaAvailable() && !isSecureStorageInstantiated()) {
      showErrorMessage(t('user.login.error.secure_storage'));
    } else {
      setRememberLogin(event.target.checked)
    }
  }
    */

  return (
      <form onSubmit={handleSubmit}>
        <div className="LoginLogoContainer">
          <img src={logoTiivii} alt="Logo" className="Logo" />
        </div>
        <h2 className="LoginTitle">{t("user.login")}</h2>

        <div className="LanguageSelector">
          <select value={i18n.language} onChange={handleLanguageChange}>
            {tenantLanguages.map((lang) => (
                <option key={lang} value={lang}>
                  {languagesName[lang]}
                </option>
            ))}
          </select>
        </div>
        {loginError && (
            <div className="ErrorBlock">
              <AlertNotification type="error" time={ERROR_TIME_IN_MILIS} showtitle={false} text={loginError} />
            </div>
        )}
        <label className="UsernameBlock">
          <input
              className="LoginInput"
              type="text"
              placeholder={t("register.nomeusuario")}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
          />
        </label>

        <label className="PasswordBlock">
          <input
              className="LoginInput"
              type={showPassword ? "text" : "password"}
              placeholder={t("register.datapassword")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
          />
          <button type="button" className="PasswordToggle" onClick={() => setShowPassword(!showPassword)}>
            <ion-icon name={showPassword ? "eye-outline" : "eye-off-outline"}></ion-icon>
          </button>
        </label>

        <div className="RememberRecoveryBlock">
          {/*
          <label className="RememberBlock">
            <input
                type="checkbox"
                checked={rememberLogin}
                onChange={handleChangeRemember}
            />
            {t("user.login.remember")}
          </label>
          */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="RecoveryLink" onClick={() => changeFormStep(FORM_STEPS.RECOVER)}>
            {t('user.login.recovery')}
          </a>
        </div>

        <div className="ButtonGroup">
          <button type="submit" className="LoginFormButton">
            {t('user.login.login')}
          </button>
          <button type="button" className="RegisterButton" onClick={handleRegister}>
            {t('user.login.register')}
          </button>
        </div>

        <div className="Separator"></div>

        <div className="NavigateAsGuestBlock">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="NavigateAsGuestButton" onClick={handleNavigateAsGuest}>
            {t('user.login.navigate_as_guest')}
          </a>
        </div>
      </form>
  );
};

export default LoginFormBackend;
