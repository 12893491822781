import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import Player from "./Player";
import "./Player.css";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import {URL_HOME, URL_LOGIN, URL_PRODUCTS, URL_VIDEODETAIL} from "../../../../app/src/Components/Routes/Location";
import {getContentPermissions, getSerialContentsService} from "../../../../app/src/services/services";
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {getToken} from "../../../../app/src/Utils/utils";
import ErrorMessageModalComponent from "../../../../app/src/Components/common/ErrorMessageModalComponent";
import {saveProductsActionCreator, turnOffLoadingActionCreator} from "../../../../app/src/actions/commonActions";
import { isCordovaAvailable } from "../../../../app/src/Utils/secureStorageUtil";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  LOAD_CONTENT_PLAYER,
  REDIRECT_TO_LOGIN,
  REDIRECT_TO_PRODUCTS
} from "../../../../detail/src/Pages/DetailPage/VideoDetailPageConstants";
import i18n from "../../../../app/src/i18n";

const PlayerPage = (props) => {
  const {id} = useParams();
  const history = useHistory();
  const {scrollRef} = props;
  const [allowed, setAllowed] = useState(undefined);
  const [messageModal, setMessageModal] = useState(false);
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const translations = useSelector((state) => state.commonState.translations);

  const [isPayRequired, setIsPayRequired] = useState(false);
  const isLoginRequired = MULTITENANT_CONSTANTS[TENANT].showLoginButton;
  const [accessBehavior, setAccessBehavior] = useState(null);
  const [textMessageErrorBody, setTextMessageErrorBody] = useState(
      "error.modal.rating.message"
  );
  const [urlToRedirect, setUrlToRedirect] = useState(null);

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const contentPermissionsCallback = (response, contentType) => {
    const allowedResponse = response?.allowed;
    if (allowedResponse !== undefined) {
      setAllowed(allowedResponse);
      allowedResponse ? setAccessBehavior(LOAD_CONTENT_PLAYER) : setAccessBehavior(REDIRECT_TO_PRODUCTS);
    } else {
      setAccessBehavior(redirectUserTo(response));
    }
    turnOffLoading();
  };

  const redirectUserTo = (response) => {
    let actionToRedirect = REDIRECT_TO_LOGIN;
    const errorBody = JSON.parse(response.error_error.http_body);
    switch (response.error_error.http_status_code) {
      case 401:
        setMessageModal(true);
        break;
      case 403:
        setTextMessageErrorBody("")
        setIsPayRequired(true);
        setTextMessageErrorBody("error.modal.paymnet.message")
        dispatch(saveProductsActionCreator(errorBody.error.products));
        setUrlToRedirect(URL_PRODUCTS + "/" + id);
        actionToRedirect = REDIRECT_TO_PRODUCTS;
        setMessageModal(true);
        break;
      default:
        console.error('Error no manejado:', response.error_error.http_status_code);
    }
    return actionToRedirect;
  }


  useEffect(() => {
    if (!id) {
      history.push(URL_HOME);
      return;
    }

    // Si el tenant no requiere login, no es necesario verificar permisos
    if (!isLoginRequired) {
      setAllowed(true); // Permitir acceso directo
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      return;
    }
    const userToken = getToken(keycloak);
      if (initialized || isCordovaAvailable()) {
        getContentPermissions(
            TENANT,
            id,
            userToken || undefined,
            undefined,
            contentPermissionsCallback
        )
        scrollRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      } else {
        setMessageModal(true);
        turnOffLoading()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, initialized, keycloak, scrollRef, isLoginRequired]);

  const goToVideoDetail = () => {
    if(!!id) {
      history.push(`${URL_VIDEODETAIL}/${id}`);
    } else {
        history.push(`${URL_HOME}`);
    }
  }

  useEffect(() => {
    if (isLoginRequired && !keycloak?.authenticated && isCordovaAvailable()) {
      history.push(URL_LOGIN);
    }
  }, [isLoginRequired, keycloak])

  const languageSelected =
      useSelector((state) => state.commonState.languageSelected) || i18n.language;
  const { t } = useTranslation();
  const getTranslation = (field) => {
    const translation = translations.find(t => t.language === languageSelected);
    return translation ? translation[field] : t(field);
  };
  const ppvModalText = getTranslation("ppv_modal_text") || "";


  return (
      <>
        <div>
          {!!allowed && <Player theme="vjs-theme-forest" id={id} />}
        </div>
        {isLoginRequired && !isPayRequired && messageModal && (
            <ErrorMessageModalComponent
                textTitle="error.modal.common.title"
                textBody="error.modal.rating.message"
                textButton="user.login"
                action={() => keycloak.login({ locale: MULTITENANT_CONSTANTS[TENANT]?.defaultLocale })}
                onClose={() => {setMessageModal(false); goToVideoDetail();}}
            />
        )}
        {isLoginRequired && isPayRequired && messageModal && (
            <ErrorMessageModalComponent
                textTitle={ppvModalText}
                textBody={""}
                textButton="user.payment"
                url={urlToRedirect}
                onClose={() => { setMessageModal(false); goToVideoDetail(); }}
            />
        )}
      </>
  );
};

export default WithAuthorization(PlayerPage);
