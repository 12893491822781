import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";

import history from "./History";
import { WithMenuRoute, WithMenuRoutePrivate } from "./WithMenuRoute";
import {
  URL_LEGAL,
  URL_HOME,
  URL_REGISTER,
  URL_LOGIN,
  URL_SELECTLOGIN,
  URL_SEARCH,
  URL_CATALOGUE,
  URL_CONTENT,
  URL_PROFILE,
  URL_RANKING,
  URL_PUBLIC_RANKING,
  URL_PLAYER,
  URL_VIDEODETAIL,
  URL_PUBLISH,
  URL_NAME_PUBLISH,
  URL_REJECT,
  URL_NAME_REJECT,
  URL_PRODUCTS,
  URL_SUCCESS_CHECKOUT,
  URL_CANCEL_CHECKOUT,
  URL_DISCOVER,
  URL_INDEX,
  URL_SECTION,
  URL_SCREEN,
} from "./Location";
import Search from "../../../../search/src/Pages/Buscar/Search";
import Ranking from "../../../../user/src/Pages/Usuario/Ranking";
import PublicRankingListPage from "../../../../user/src/Pages/Usuario/PublicRankingListPage";
import UserProfile from "../../../../user/src/Pages/Usuario/UserProfile";
import { GlobalLoadingComponent } from "../Loader/GlobalLoadingComponent";
import ExpiredSessionModalComponent from "../common/ExpiredSessionModalComponent";
import ProductsComponent from "../../../../products/src/Pages/Products/ProductsComponent";
import CheckoutComponent from "../../../../checkout/src/Pages/Checkout/CheckoutComponent";
import PublishRejectComponent from "../../../../publish/src/Pages/publish/PublishRejectComponent";
import VideoDetailPage from "../../../../detail/src/Pages/DetailPage/VideoDetailPage";
import PlayerPage from "../../../../player/src/Pages/Player/PlayerPage";
import SectionComponent from "../../../../catalogue/src/Pages/Section/SectionComponent";
import Content from "../../../../catalogue/src/Pages/Content/Content";
import Catalogo from "../../../../catalogue/src/Pages/Catalogo/Catalogo";
import Legal from "../../../../user/src/Pages/Legal/Legal";
import HomeComponent from "../../../../home/src/Pages/Home/HomeComponent";
import Register from "../../../../user/src/Pages/Register/Register";
import SelectAge from "../../../../user/src/Pages/SelectAge/SelectAge";
import App from "../../App";
import { useState } from "react";
import LoginRegisterRecoverForm from "../../Pages/LoginRegisterRecoverForm/LoginRegisterRecoverForm";
import { getTokensFromSecureStorage, initSecureStorage, isCordovaAvailable } from "../../Utils/secureStorageUtil";
import { initializeKeycloak } from "../../Utils/keycloak";
import { turnOffLoadingActionCreator, userLoginActionCreator } from "../../actions/commonActions";
import { useEffect } from "react";

const Routes = ({ changekeycloak }) => {
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useDispatch();
  const [scrollRef, setScrollRef] = useState(null);
  const [isGuest, setIsGuest] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCheckingForCredentials, setIsCheckingForCredentials] = useState(isCordovaAvailable());
  const sessionExpiredModal = useSelector((state) => state.commonState.isSessionExpired);

  const loading = useSelector((state) => state.commonState.loading);

  const checkForTokensInSecureStorage = () => {
    getTokensFromSecureStorage().then(({ token, refreshToken, username }) => {
      initializeKeycloak(token, refreshToken, username);
      dispatch(userLoginActionCreator(username));
      setIsLoggedIn(true);
      setIsCheckingForCredentials(false);
      dispatch(turnOffLoadingActionCreator());
    })
    .catch((error) => {
      setIsCheckingForCredentials(false);
      setIsLoggedIn(false);
      dispatch(turnOffLoadingActionCreator());
    });
  }

  useEffect(() => {
    if (keycloak && (initialized || isCordovaAvailable())) {
      if (isCordovaAvailable()) {
        initSecureStorage(
          () => {checkForTokensInSecureStorage()},
          (error) => {setIsCheckingForCredentials(false); dispatch(turnOffLoadingActionCreator())}
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, initialized])

  if ((!initialized || !keycloak.clientId) && !isCordovaAvailable()) {
    return null;
  }

  const handleNavigateAsGuest = () => {
    setIsGuest(true);
  }

  const renderLoginOrHomeComponent = (props) => {
    if (isCordovaAvailable()) {
      if (isCheckingForCredentials) {
        return <></>;
      } else if (!isLoggedIn) {
        return <LoginRegisterRecoverForm handleNavigateAsGuest={handleNavigateAsGuest} {...props} />;
      }
    }
    return <HomeComponent {...props} scrollRef={scrollRef} isGuest={isGuest} />;
  };

  return (
    <Router history={history}>
      <App className="loader" setScrollRef={setScrollRef}>
        <Switch>
          <Route path={`*${URL_REGISTER}`} component={Register} />
          <Route path={`${URL_LOGIN}${URL_HOME}`} render={(props) => <Legal {...props} />} />
          <Route path={`${URL_LEGAL}${URL_LOGIN}${URL_HOME}`} render={(props) => <HomeComponent {...props} scrollRef={scrollRef} />} />
          <Route exact path={`*${URL_HOME}`} render={(props) => <HomeComponent {...props} scrollRef={scrollRef} />} />
          <Route exact path={`${URL_SCREEN}/:screenName/:screenId`} render={(props) => <HomeComponent {...props} scrollRef={scrollRef} />} />
          <Route path={URL_LEGAL} component={Legal} />
          {/*<Route path={URL_ERRORPAGE} component={ErrorPage} />*/}
          <Route path={`${URL_PUBLISH}/:contentId`} render={() => <PublishRejectComponent event={URL_NAME_PUBLISH} />} />
          <Route path={`${URL_REJECT}/:contentId`} render={() => <PublishRejectComponent event={URL_NAME_REJECT} />} />
          <Route path={URL_SELECTLOGIN} render={(props) => <SelectAge changekeycloak={changekeycloak} {...props} />} />
          <Route path={URL_LOGIN} render={(props) => <LoginRegisterRecoverForm handleNavigateAsGuest={handleNavigateAsGuest} {...props} />} />
          {/*Paxinas internas*/}
          <WithMenuRoute exact path={URL_DISCOVER} component={Catalogo} noScroll={true} scrollRef={scrollRef} />
          <WithMenuRoute path={`${URL_SECTION}/:sectionName`} component={SectionComponent} scrollRef={scrollRef} />
          <WithMenuRoute path={URL_SEARCH} component={Search} scrollRef={scrollRef} />
          <WithMenuRoute path={URL_CATALOGUE} component={Catalogo} scrollRef={scrollRef} />
          <WithMenuRoute path={`${URL_CONTENT}/:type/:id`} component={Content} noScroll={true}  scrollRef={scrollRef} />
          <WithMenuRoute path={`${URL_PLAYER}/:id`} component={PlayerPage} nofooter={true} scrollRef={scrollRef} />
          <WithMenuRoute path={`${URL_VIDEODETAIL}/:id`} component={VideoDetailPage} scrollRef={scrollRef} />
          <WithMenuRoute path={`${URL_PRODUCTS}/:id`} component={ProductsComponent} />
          <WithMenuRoute path={`${URL_SUCCESS_CHECKOUT}/:id`} component={CheckoutComponent} success={true} />
          <WithMenuRoute path={`${URL_CANCEL_CHECKOUT}/:id`} component={CheckoutComponent} success={false} />
          <WithMenuRoute path={URL_PUBLIC_RANKING} component={PublicRankingListPage} />
          <WithMenuRoutePrivate path={URL_RANKING} component={Ranking} />
          <WithMenuRoutePrivate path={URL_PROFILE} component={UserProfile} scrollRef={scrollRef} />
          <Route path={URL_INDEX} render={renderLoginOrHomeComponent} />
        </Switch>
        {loading && <GlobalLoadingComponent />}
        {sessionExpiredModal && <ExpiredSessionModalComponent />}
      </App>
    </Router>
  );
};

export default Routes;
