import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import "./Ranking.css";
import {
  getBoardRankingsService,
  getRankingBoardIdService,
} from "../../../../app/src/services/services";
import RankingListComponent from "./RankingComponents/components/RankingListComponent";
import {
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";

const PublicRankingListPage = () => {
  const dispatch = useDispatch();
  const [rankingData, setRankingData] = useState(null);
  const [renderMobileVersion, setRenderMobileVersion] = useState(false);

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const getRankingBoardIdCallback = (response) => {
    const rankingConsumo = response.data.find(item => item.name === "Ranking consumo");
    getBoardRankingsService(TENANT, rankingConsumo.id, getBoardRankingCallback);
  }

  const getBoardRankingCallback = (response) => {
    setRankingData(response.ranking);
    turnOffLoading();
  };

  if (!MULTITENANT_CONSTANTS[TENANT].gamification) {
    return <Redirect to="/" />;
  }

  useEffect(() => {
    turnOnLoading();
    getRankingBoardIdService(TENANT, getRankingBoardIdCallback);
  }, []);

  return (
    <div className="Ranking">
      {rankingData !== null && (
        <div className="Ranking-container">
          <div className="Public-ranking-list">
            <RankingListComponent 
              rankings={rankingData} 
              renderMobileVersion={renderMobileVersion} 
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PublicRankingListPage;
