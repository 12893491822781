import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

import {
  getContentPermissions,
  logUserAnalyticService,
} from "../../../../app/src/services/services";
import "./ProductsComponent.css";
import {
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import { LOCAL_STORAGE_KEY__LOCALE } from "../../../../app/src/Utils/Constants";
import {
  URL_CANCEL_CHECKOUT,
  URL_SUCCESS_CHECKOUT,
} from "../../../../app/src/Components/Routes/Location";
import {
  PRODUCT_RECURRING_PERIOD,
  PRODUCT_RECURRING_TYPE,
} from "./ProductsConstants";
import { getProductCheckoutService } from "../../../../app/src/services/services";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {
  DETAIL_VALUE,
  PAGE_ID_KEY,
  PAGE_VIEW_ACTION,
} from "../../../../app/src/analyticsConstants/analyticsConstants";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import { isCordovaAvailable } from "../../../../app/src/Utils/secureStorageUtil";
import { useHistory } from "react-router-dom";

const ProductsComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();
  const { keycloak, initialized } = useKeycloak();
  const username = keycloak?.tokenParsed?.preferred_username;

  const locale = localStorage.getItem(LOCAL_STORAGE_KEY__LOCALE);
  const tenantStyles = useSelector((state) => state.commonState.tenantStyles);

  const [productsData, setProductsData] = useState([]);
  const [checkoutURL, setCheckoutURL] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    !!username && logUserAnalyticService(
      TENANT,
      username,
      PAGE_VIEW_ACTION,
      PAGE_ID_KEY,
      DETAIL_VALUE,
      keycloak?.token
    );
  }, [username]);

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const productCheckoutCallback = (data) => {
    setCheckoutURL(data.url);
  };

  useEffect(() => {
    if (!!id && (!!keycloak?.token || (initialized || isCordovaAvailable()))) {
      turnOnLoading();
      getContentPermissions(TENANT, id, keycloak?.token, null, (response) => {
        try {
          const errorBody = response?.error_error?.http_body
            ? JSON.parse(response.error_error.http_body)
            : null;
   
          const products = errorBody?.error?.products || [];
  
          if (products.length > 0) {
            const validProducts = products
              .filter(product => product.products_id)
              .map(product => ({
                ...product.products_id,
                productId: product.products_id.id
              }));
            setProductsData(prevProducts => [...prevProducts, ...validProducts]);
          }
        } catch (error) {
          console.error("❌ Error al procesar los permisos del contenido:", error);
        } finally {
          turnOffLoading();
        }
      });
    }
  }, [id, keycloak]);

  const handleCheckOutProduct = (productId) => {
    const cancelUrl = `${window.location.origin}${URL_CANCEL_CHECKOUT}/${id}`;
    const successUrl = `${window.location.origin}${URL_SUCCESS_CHECKOUT}/${id}`;
    const body = { successUrl, cancelUrl };

    getProductCheckoutService(
      productId,
      MULTITENANT_CONSTANTS[TENANT].tenantServer,
      username,
      locale,
      keycloak?.token,
      body,
      productCheckoutCallback
    );

    setLoading(productId);
  };

  useEffect(() => {
    if (checkoutURL && loading) {
      setLoading(null);
      if (isCordovaAvailable()) {
        const browserRef = window.cordova.InAppBrowser.open(checkoutURL, "_blank", "location=no,toolbar=no");
        browserRef.addEventListener('loadstart', (event) => {
          if (event.url === `${window.location.origin}${URL_CANCEL_CHECKOUT}/${id}`) {
            browserRef.close();
            history.push(`${URL_CANCEL_CHECKOUT}/${id}`);
          } else if (event.url === `${window.location.origin}${URL_SUCCESS_CHECKOUT}/${id}`) {
            browserRef.close();
            history.push(`${URL_SUCCESS_CHECKOUT}/${id}`);
          }
        });
      } else {
        const win = window.open(checkoutURL);
        win.focus();
      }
      setCheckoutURL(null);
    }
  }, [checkoutURL]);

  const transformPriceNumber = (number) => {
    const result = number / 100;
    return result.toFixed(2);
  };

  const isMonthlyPayment = (product) => {
    return (
      product.recurringPeriod === PRODUCT_RECURRING_PERIOD &&
      product.recurringType === PRODUCT_RECURRING_TYPE
    );
  };

  const backgroundImageByTenant = () => {
    return `url(${tenantStyles?.backgroundRegister})`;
  };

  return (
    <div
      className={"Page"}
      style={{ backgroundImage: backgroundImageByTenant() }}
    >
      {productsData.length > 0 && (
        <div className="Container">
          <div className="PageHeader">
            <div>
              <ion-icon name="lock-open" id="LockIcon" />
            </div>
            <p className="PageTitle">{t("products.choose.pack")}</p>
          </div>
          <div className="ProductsContainer">
            {productsData.map((product, index) => (
              <div
                className="ProductCard"
                key={`${product.productId}-${index}`}
                onClick={() => handleCheckOutProduct(product.productId)}
                style={{
                  background: `url(${product.image?.data?.url}) no-repeat center center`,
                  backgroundSize: "cover",
                }}
              >
                <div className="Gradient" />
                {loading === product.productId && (
                  <div className="SpinnerContainer">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}
                <div className={"groupTitleDescription"}>
                  <div className={"ProductMainInfo"}>
                  <strong className={"ProductTitle"}>{product.name}</strong>
                    <strong className={"ProductPrice"}>
                      {transformPriceNumber(product.price)}
                    </strong>
                    <strong className={"ProductCurrency"}>
                      {product.currency}{" "}
                      {isMonthlyPayment(product)
                        ? `/ ${t("products.month")}`
                        : ""}
                    </strong>
                  </div>
                  <div className={"ProductIconText"}>
                    {product.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default WithAuthorization(ProductsComponent);
